import React from "react"
import { graphql } from "gatsby"

import { withoutAuthentication } from "../../../hoc/withAuthentication"
import { Auth as Page } from "../../../components/Account/Login/Auth/Auth"

export const query = graphql`
  query {
    page: sanityPageAccountLogin {
      title
      image: _rawImage(resolveReferences: { maxDepth: 2 })
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
    }
  }
`

const Component = withoutAuthentication(({ data, ...props }): JSX.Element => <Page {...props} {...data} />)
export default Component
